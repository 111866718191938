import * as Yup from "yup";
import sanitizeHtml from 'sanitize-html';

const RequiredMessage = "Required field";

export const EditNewsValidationSchema = Yup.object().shape({
    referenceName: Yup.string().notRequired().nullable(),
    title: Yup.string().trim().typeError(RequiredMessage).required(RequiredMessage),
    category: Yup.string().typeError(RequiredMessage).required(RequiredMessage),

    label: Yup.string().notRequired(),

    provider: Yup.string().notRequired(),

    description: Yup
        .string()
        .test('description', RequiredMessage, val => !!(val && sanitizeHtml(val, { allowedTags: [], allowedAttributes: {} }).trim()))
        .typeError(RequiredMessage),
    newsDateTime: Yup.date()
        .typeError(RequiredMessage)
        .required(RequiredMessage),

    externalSourceLink: Yup.string().notRequired().nullable(),
    transactionLink: Yup.string().notRequired().nullable(),
    published: Yup.boolean().notRequired(),
    cloManagerReferenceName: Yup.string().notRequired().nullable(),
    arrangerReferenceName: Yup.string().notRequired().nullable(),
});
